<template>
  <div class="s-container page">
    <div v-if="isError">
      <MemberButton
        button-text="回官方帳號"
        type="OA"
      />
    </div>
  </div>
</template>

<script>
import checkApi from "@/apis/liff/v2/check";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";

export default {
  mixins: [moduleMap],
  components: {
    MemberButton,
  },
  data() {
    return {
      isError: false,
    }
  },
  mounted() {
    this.redirect()
  },
  methods: {
    async redirect() {
      try {
        const { data } = await checkApi.checkAndRedirect(this.$route.params.check_code);
        const result = data.data
        if (result.url && result.is_external) {
          window.location.href = result.url
          return
        }

        if (result.type) {
          const orgCode = this.$route.params.orgCode || this.$route.params.org_code
          if (this.moduleMap.find((x) => x.type == result.type)) {
            this.$router.push({
              name: this.moduleMap.find((x) => x.type == result.type).value,
              params: { org_code: orgCode },
            })
            return
          }
        }

        if (result.type.startsWith('liff_page')) {
          const orgCode = this.$route.params.orgCode || this.$route.params.org_code
          const path = result.type.replace('liff_page', `/${orgCode}/liff/page`);
          this.$router.push(path);
          return
        }
      } catch (error) {
        console.log(error)
        this.isError = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}
</style>
