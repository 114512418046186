import https from "./https";
import store from "@/store";

const checkApi = {
  checkAndRedirect(path) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/check/${path}`);
  },
};

export default checkApi
